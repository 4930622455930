.App {
  text-align: center;
}
.bg {
  background: linear-gradient(
    90deg,
    rgba(133, 127, 232, 1) 12%,
    rgba(35, 193, 150, 1) 51%,
    rgba(34, 178, 207, 1) 95%
  );
  max-width: 100vw;
  height: 100vh;
}

.center {
  font-size: 50px;
  text-align: center;
}
