.simpleSwap {
  align-items: center;
  position: relative;
  margin-top: 10vh;
  padding: 1em 1em;
  width: 90vh;
  height: 33vw;
}

.disclaimer {
  align-items: center;
  font-size: 50px;
  padding-top: 150px;
}

@media screen and (max-width: 800px) {
  .not-mobile {
    display: none;
  }

  .disclaimer {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sarcastic-redirect {
    padding-top: 100px;
    padding: 100px 20px 20px 20px; /*top right bottom left*/
    font-size: 1.5rem;
  }
}
