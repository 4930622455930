@import url("https://fonts.googleapis.com/css?family=Work+Sans:300,600");

:root {
  --background: rgba(0, 0, 0);
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  left: 0;
  background: #222;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

.logo {
  padding-top: 0.5rem;
  color: white;
  text-decoration: none;
}

.content {
  height: 200vh;
  background-image: url(//unsplash.it/1000/1000);
  background-color: #333;
  background-blend-mode: multiply;
  background-size: cover;
  display: grid;
  place-items: left;
}

/* navigation styles start here */

.header1 {
  background: var(--background);
  text-align: left;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 999;
  width: 100%;
  height: 4rem;
}

.nav-toggle {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.nav-toggle:focus ~ .nav-toggle-label {
  outline: 3px solid rgba(white, 0.75);
}

.nav-toggle-label {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: white;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

.connect-wallet {
  background: none;
  border: none;
  color: white;
  margin-top: -1px;
}

nav {
  display: flex !important;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  top: 100%;
  left: 10;
  background: var(--background);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;
}

.header1 nav ul {
  display: flex;
  justify-content: flex-end;
  /* margin-left: 40vw; */
  margin-top: -50px;
  list-style: none;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

nav li:hover {
  color: rgb(235, 214, 214);
}

.nav-toggle:checked ~ nav {
  transform: scale(1, 1);
}

.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 800px) {
  .nav-toggle-label {
    display: none;
  }

  header {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
  }

  .logo {
    line-height: auto;
    color: white;
    grid-column: 2 / 3;
  }

  nav {
    all: unset;
    position: relative;
    text-align: right;
    transition: none;
    transform: scale(1, 1);
    background: none;
    top: initial;
    left: initial;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  nav ul {
    display: flex;
  }

  nav li {
    margin-left: 3em;
    margin-bottom: 0;
  }

  nav a {
    opacity: 1;
    position: relative;
  }

  nav a::before {
    content: "";
    display: block;
    height: 5px;
    background: white;
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    transform: scale(0, 1);
    transition: transform ease-in-out 250ms;
  }

  nav a:hover::before {
    transform: scale(1, 1);
  }
}
