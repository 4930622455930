:root {
  /* --font-family: Apple Chancery, cursive; */
  /* overflow: hidden; */
}

.container {
  background: lightgreen;
  height: 50vh;
  width: 100vw;
  padding: 0.75em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin: 10vh auto 0;
}

.title-card {
  position: relative;
  padding: 20px;
  margin: 30px auto 0;
  background: #fff;
  border-radius: 20px;
  min-width: 300px !important;
  max-width: 90vh;
}

.description {
  margin-top: 20px !important;
  color: black;
}

.title-card > a {
  position: relative;
  padding-top: 20px !important;
  margin-top: 1em;
  font-size: 2rem;
  font-weight: 300;
  font-family: var(--font-family);
  padding-bottom: 20px !important;
}

.homepageBG-img {
  display: flex;
  position: relative;
  margin-left: auto;
  padding: 1em 1em;
}

.chain-price {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.wallet-balance {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.account-total {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.balances {
  padding: 30px;
}

.loading {
  font-size: 75px !important;
  display: flex;
  margin: 0 auto 0 !important;
}

.title-underline {
  border-bottom: 1px solid;
}
