@import url(https://fonts.googleapis.com/css?family=Work+Sans:300,600);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}
.bg {
  background: linear-gradient(
    90deg,
    rgba(133, 127, 232, 1) 12%,
    rgba(35, 193, 150, 1) 51%,
    rgba(34, 178, 207, 1) 95%
  );
  max-width: 100vw;
  height: 100vh;
}

.center {
  font-size: 50px;
  text-align: center;
}

:root {
  --background: rgba(0, 0, 0);
  overflow: hidden;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  left: 0;
  background: #222;
  font-family: "Work Sans", sans-serif;
  font-weight: 400;
}

.logo {
  padding-top: 0.5rem;
  color: white;
  text-decoration: none;
}

.content {
  height: 200vh;
  background-image: url(//unsplash.it/1000/1000);
  background-color: #333;
  background-blend-mode: multiply;
  background-size: cover;
  display: grid;
  place-items: left;
}

/* navigation styles start here */

.header1 {
  background: rgba(0, 0, 0);
  background: var(--background);
  text-align: left;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  z-index: 999;
  width: 100%;
  height: 4rem;
}

.nav-toggle {
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important;
}

.nav-toggle:focus ~ .nav-toggle-label {
  outline: 3px solid rgba(white, 0.75);
}

.nav-toggle-label {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1em;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-toggle-label span,
.nav-toggle-label span::before,
.nav-toggle-label span::after {
  display: block;
  background: white;
  height: 2px;
  width: 2em;
  border-radius: 2px;
  position: relative;
}

.nav-toggle-label span::before,
.nav-toggle-label span::after {
  content: "";
  position: absolute;
}

.nav-toggle-label span::before {
  bottom: 7px;
}

.nav-toggle-label span::after {
  top: 7px;
}

.connect-wallet {
  background: none;
  border: none;
  color: white;
  margin-top: -1px;
}

nav {
  display: flex !important;
  align-items: center;
  justify-content: center;
  align-items: flex-end;
  text-align: left;
  top: 100%;
  left: 10;
  background: rgba(0, 0, 0);
  background: var(--background);
  width: 100%;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: top;
          transform-origin: top;
  transition: -webkit-transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out;
  transition: transform 400ms ease-in-out, -webkit-transform 400ms ease-in-out;
}

.header1 nav ul {
  display: flex;
  justify-content: flex-end;
  /* margin-left: 40vw; */
  margin-top: -50px;
  list-style: none;
}

nav li {
  margin-bottom: 1em;
  margin-left: 1em;
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
}

nav li:hover {
  color: rgb(235, 214, 214);
}

.nav-toggle:checked ~ nav {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.nav-toggle:checked ~ nav a {
  opacity: 1;
  transition: opacity 250ms ease-in-out 250ms;
}

@media screen and (min-width: 800px) {
  .nav-toggle-label {
    display: none;
  }

  header {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) 1fr;
  }

  .logo {
    line-height: auto;
    color: white;
    grid-column: 2 / 3;
  }

  nav {
    all: unset;
    position: relative;
    text-align: right;
    transition: none;
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
    background: none;
    top: auto;
    top: initial;
    left: auto;
    left: initial;
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  nav ul {
    display: flex;
  }

  nav li {
    margin-left: 3em;
    margin-bottom: 0;
  }

  nav a {
    opacity: 1;
    position: relative;
  }

  nav a::before {
    content: "";
    display: block;
    height: 5px;
    background: white;
    position: absolute;
    top: -0.75em;
    left: 0;
    right: 0;
    -webkit-transform: scale(0, 1);
            transform: scale(0, 1);
    transition: -webkit-transform ease-in-out 250ms;
    transition: transform ease-in-out 250ms;
    transition: transform ease-in-out 250ms, -webkit-transform ease-in-out 250ms;
  }

  nav a:hover::before {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}

:root {
  /* --font-family: Apple Chancery, cursive; */
  /* overflow: hidden; */
}

.container {
  background: lightgreen;
  height: 50vh;
  width: 100vw;
  padding: 0.75em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  gap: 20px;
  margin: 10vh auto 0;
}

.title-card {
  position: relative;
  padding: 20px;
  margin: 30px auto 0;
  background: #fff;
  border-radius: 20px;
  min-width: 300px !important;
  max-width: 90vh;
}

.description {
  margin-top: 20px !important;
  color: black;
}

.title-card > a {
  position: relative;
  padding-top: 20px !important;
  margin-top: 1em;
  font-size: 2rem;
  font-weight: 300;
  font-family: var(--font-family);
  padding-bottom: 20px !important;
}

.homepageBG-img {
  display: flex;
  position: relative;
  margin-left: auto;
  padding: 1em 1em;
}

.chain-price {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.wallet-balance {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.account-total {
  display: inline-block;
  padding: 20px 40px 20px 40px;
}

.balances {
  padding: 30px;
}

.loading {
  font-size: 75px !important;
  display: flex;
  margin: 0 auto 0 !important;
}

.title-underline {
  border-bottom: 1px solid;
}

.container {
    display: inline-block;
    background: none !important;
} 

.sign-up {
    padding: -2rem;
}
.d-block {
  overflow: hidden;
}

.container1 {
  overflow: hidden;
}

.buttons {
  display: flex;
  margin: auto;
  justify-content: center;
}

.editIcon {
  margin-bottom: 10px;
}

.update-profile {
  width: 20px;
  height: 50px;
  border: none !important;
  background: none !important;
  color: rgb(78, 208, 252);
}


.d-body {
  background-color: #282c34;
  margin-top: 0 !important;
  height: 100vh;
  overflow: hidden !important;
}

main {
  padding: 10px;
  height: 95vh;
  /* margin: 10vh 0 10vh; */
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.messageForm {
  height: 5vh;
  position: fixed;
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  display: flex;
  font-size: 1rem;
}

button .messageForm {
  width: 20%;
  background-color: rgb(56, 56, 143);
  height: auto !important;
}

input {
  line-height: 1.5;
  width: 94%;
  height: auto !important;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  color: white;
  outline: none;
  border: none;
  padding: 0 10px;
}

.send-button {
  background-color: #68e0b2; /* Green */
  border: none;
  color: black;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-size: 1.25rem;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.icon {
  padding-bottom: 5px;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 12px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.userIMG {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.displayName {
  padding: 0px 0.1rem 0.5rem 0.1rem;
  color: white;
}

.simpleSwap {
  align-items: center;
  position: relative;
  margin-top: 10vh;
  padding: 1em 1em;
  width: 90vh;
  height: 33vw;
}

.disclaimer {
  align-items: center;
  font-size: 50px;
  padding-top: 150px;
}

@media screen and (max-width: 800px) {
  .not-mobile {
    display: none;
  }

  .disclaimer {
    font-size: 1.75rem;
    font-weight: bold;
  }

  .sarcastic-redirect {
    padding-top: 100px;
    padding: 100px 20px 20px 20px; /*top right bottom left*/
    font-size: 1.5rem;
  }
}

